<template>
  <div class="testimonials w-background-blue-light z-border-radius-20">
    <b-container>
      <b-row class="a-section-title">
        <b-col>
          <h2 class="text-center">
            <span class="w-color-primary z-fancy-underline">
              {{ $t("Odporúčania") }}<svg-fancy-underline-icon />
              <img src="/references.png" class="a-small-logo" alt="" />
            </span>
            <span class="w-color-black"> {{ fromUserGroup }}</span>
          </h2>
        </b-col>
      </b-row>
      <flickity
        ref="flickity"
        :options="flickityOptions"
        @init="onFlickityInit"
      >
        <b-col
          v-for="(testimonial, i) in testimonials"
          :key="i"
          xl="4"
          md="6"
          class="b-col"
        >
          <div class="d-flex justify-content-center card-container">
            <div class="a-card d-flex flex-column w-background-white border">
              <div class="text-center mt-3">
                <img :src="testimonial.image" :alt="testimonial.title" />
              </div>
              <div class="mx-4">
                <h4 class="w-color-black mt-4">
                  {{ $t(testimonial.title) }}
                </h4>
                <p class="mt-3 w-color-dark-gray">
                  {{ $t(testimonial.description) }}
                </p>
              </div>
            </div>
          </div>
        </b-col>
      </flickity>

      <div class="d-flex justify-content-start buttons-wrapper">
        <button
          class="z-icon-wrapper -medium border"
          :aria-label="$t('do-skol.next-testimonial')"
          @click.prevent="previous()"
        >
          <svg-arrow-right-icon class="left-icon w-color-primary" />
        </button>
        <button
          class="z-icon-wrapper -medium border"
          :aria-label="$t('do-skol.previous-testimonial')"
          @click.prevent="next()"
        >
          <svg-arrow-right-icon class="w-color-primary" />
        </button>
      </div>
    </b-container>
  </div>
</template>

<script>
import testimonials from "/assets/json/testimonials.json";

export default {
  components: {
    "svg-fancy-underline-icon": () =>
      import("/assets/icons/fancyUnderline.svg?inline"),
    flickity: () => import("vue-flickity"),
    "svg-arrow-right-icon": () => import("/assets/icons/arrowRight.svg?inline"),
  },

  computed: {
    testimonials() {
      return testimonials.teachers;
    },
    fromUserGroup() {
      return this.$t("od učiteľov");
    },
  },

  created() {
    this.flickityOptions = {
      prevNextButtons: false,
      cellAlign: "left",
      draggable: true,
      freeScroll: false,
      wrapAround: false,
    };
    this.isCarouselDragged = false;
  },

  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },

    onFlickityInit() {
      // prevent clicking on carousel cards while dragging
      this.$refs.flickity.on(
        "dragStart",
        () => (this.isCarouselDragged = true)
      );
      this.$refs.flickity.on("dragEnd", () => (this.isCarouselDragged = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.flickity-slider {
  margin: 0 auto;
}
.b-col {
  display: flex;
  align-items: stretch;
  min-height: 100%;
}

.card-container {
  padding-bottom: 1.25rem;
}

.testimonials {
  margin: 3.75rem auto;
  max-width: 102rem;

  @media only screen and (max-width: 575px) {
    margin: 2rem auto;
  }
}
.a-card {
  min-height: 22.5rem;
  margin-bottom: 1rem;
  border-radius: 0.625rem;
  box-shadow: 0px 0.625rem 1.87rem rgba(208, 226, 235, 0.6);
}

.buttons-wrapper {
  padding: 0 1rem;
  width: 113px;

  .left-icon {
    transform: rotate(180deg);
  }
  .z-icon-wrapper {
    z-index: 1000;

    &:not(:first-of-type) {
      margin-left: 13px;
    }
  }
}

::v-deep .flickity-page-dots {
  display: flex;
  align-items: center;
  margin-left: 7.5rem;
  bottom: -25px;

  .flickity-viewport {
    overflow: hidden;
  }

  .dot {
    background: var(--a-color-primary);
  }

  &.is-selected {
    background: var(--a-color-primary, #333);
    width: 0.75rem;
    height: 0.75rem;
  }
}
</style>
